
  
  html {
    overflow-x: hidden;
    font-size: 1em; }
  
  body {
    font-family: "Work Sans", Arial, sans-serif; }
  
  input, textarea, p {
    font-weight: 300; }
  
  ::-moz-selection {
    background: #000;
    color: #fff; }
  
  ::selection {
    background: #000;
    color: #fff; }
  
  ::-moz-selection {
    background: #000;
    color: #fff; }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300 !important; }
  
  a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
  
  .probootstrap-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 7em 0; }
    .probootstrap-cover .overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .probootstrap-cover a:hover {
      color: #fff; }
    .probootstrap-cover h2 {
      color: #fff; }
    .probootstrap-cover p {
      color: rgba(255, 255, 255, 0.7); }
    .probootstrap-cover > .container > .row {
      padding: 7em 0; }
  
  .btn {
    padding: 15px 25px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .15em;
    font-weight: 400; }
    .btn.btn-primary {
      color: #fff; }
    .btn:hover, .btn:active, .btn:focus {
      -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  
  .bg_image, .probootstrap-section-half .probootstrap-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  
  .with-overlay {
    position: relative; }
    .with-overlay:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: rgba(0, 0, 0, 0.4); }
  
  .navbar-brand {
    text-transform: uppercase;
    letter-spacing: .3em; }
  
  .navbar-toggler span i {
    font-size: 30px; }
  
  .probootstrap_navbar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%; }
    @media (max-width: 991px) {
      .probootstrap_navbar {
        background: rgba(0, 0, 0, 0.6); } }
    .probootstrap_navbar .nav-item .nav-link {
      padding: 1.6em 1em;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .1em;
      font-weight: 400; }
      @media (max-width: 991px) {
        .probootstrap_navbar .nav-item .nav-link {
          padding: .5em 0; } }
      .probootstrap_navbar .nav-item .nav-link.active {
        color: #fff; }
    .probootstrap_navbar.scrolled {
      background: rgba(0, 0, 0, 0.8);
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      margin-top: -130px; }
      .probootstrap_navbar.scrolled .nav-item .nav-link {
        padding: .5em 1em; }
      .probootstrap_navbar.scrolled.awake {
        margin-top: 0px;
        -webkit-transition: .3s all ease-out;
        -o-transition: .3s all ease-out;
        transition: .3s all ease-out; }
      .probootstrap_navbar.scrolled.sleep {
        -webkit-transition: .3s all ease-out;
        -o-transition: .3s all ease-out;
        transition: .3s all ease-out; }
  
  .border-bottom {
    display: block;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .border-bottom:after {
      width: 50px;
      height: 2px;
      background: #00CA4C;
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
  
  #section-feature-testimonial blockquote p {
    color: #000; }
  
  #section-feature-testimonial .probootstrap-author img {
    width: 70px;
    margin-bottom: 10px; }
  
  #section-feature-testimonial .probootstrap-author a {
    display: block; }
    #section-feature-testimonial .probootstrap-author a span {
      display: block; }
    #section-feature-testimonial .probootstrap-author a .probootstrap-name {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .2em;
      color: #000;
      font-weight: 700; }
    #section-feature-testimonial .probootstrap-author a .probootstrap-title {
      color: #b3b3b3; }
  
  #section-city-guides {
    border-top: 1px solid #e6e6e6; }
  
  .probootstrap-thumbnail {
    position: relative;
    display: block;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    opacity: 1; }
    .probootstrap-thumbnail.sleep {
      opacity: .5; }
    .probootstrap-thumbnail img {
      position: relative;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      display: block;
      bottom: 0;
      z-index: 2; }
    .probootstrap-thumbnail h3 {
      z-index: 1;
      display: block;
      text-align: center;
      position: relative;
      -webkit-transition: .2s all ease;
      -o-transition: .2s all ease;
      transition: .2s all ease;
      opacity: 0;
      visibility: hidden;
      bottom: 30px; }
      @media (max-width: 991px) {
        .probootstrap-thumbnail h3 {
          font-size: 24px;
          bottom: 0; } }
      @media (max-width: 767px) {
        .probootstrap-thumbnail h3 {
          opacity: 1;
          visibility: visible;
          bottom: 0;
          margin-top: 10px; } }
    .probootstrap-thumbnail:hover h3 {
      opacity: 1;
      visibility: visible;
      bottom: -20px; }
      @media (max-width: 991px) {
        .probootstrap-thumbnail:hover h3 {
          bottom: 0px; } }
    .probootstrap-thumbnail:hover img {
      -webkit-transform: scale(1.08);
      -ms-transform: scale(1.08);
      transform: scale(1.08);
      bottom: 20px;
      -webkit-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2); }
      @media (max-width: 991px) {
        .probootstrap-thumbnail:hover img {
          bottom: 20px;
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1); } }
      @media (max-width: 767px) {
        .probootstrap-thumbnail:hover img {
          bottom: 0px;
          opacity: 1;
          visibility: visible; } }
  
  .probootstrap_select-wrap {
    position: relative; }
    .probootstrap_select-wrap:before {
      font-family: 'Ionicons';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f3d0";
      font-size: 20px;
      position: absolute;
      right: 15px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
  
  .probootstrap-section-half {
    float: left;
    width: 100%; }
    .probootstrap-section-half .probootstrap-image,
    .probootstrap-section-half .probootstrap-text {
      width: 50%; }
      @media (max-width: 991px) {
        .probootstrap-section-half .probootstrap-image,
        .probootstrap-section-half .probootstrap-text {
          width: 100%; } }
    @media (max-width: 767px) {
      .probootstrap-section-half .probootstrap-image {
        height: 400px; } }
    .probootstrap-section-half .probootstrap-text {
      background: #ECECEC; }
      .probootstrap-section-half .probootstrap-text .probootstrap-inner {
        padding: 7em; }
        @media (max-width: 991px) {
          .probootstrap-section-half .probootstrap-text .probootstrap-inner {
            padding: 3em; } }
        @media (max-width: 991px) {
          .probootstrap-section-half .probootstrap-text .probootstrap-inner {
            padding-left: 15px;
            padding-right: 15px; } }
  
  .probootstrap_section {
    padding: 7em 0;
    float: left;
    width: 100%; }
    .probootstrap_section.bg-light {
      background: #ECECEC !important; }
  
  .probootstrap_feature-v1 {
    padding: 3em;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    position: relative;
    top: 0;
    border-radius: 4px; }
    .probootstrap_feature-v1:hover, .probootstrap_feature-v1:focus {
      top: -10px;
      background: #fff;
      -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05); }
      @media (max-width: 991px) {
        .probootstrap_feature-v1:hover, .probootstrap_feature-v1:focus {
          top: 0; } }
  
  .probootstrap-border-top {
    border-top: 1px solid #ECECEC; }
  
  @media (max-width: 991px) {
    .probootstrap-section-heading {
      font-size: 28px !important; } }
  
  .probootstrap-media {
    background: #fff;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05); }
    @media (max-width: 991px) {
      .probootstrap-media {
        display: block !important; } }
    .probootstrap-media .probootstrap-media-image {
      background-size: cover;
      width: 50%; }
      @media (max-width: 991px) {
        .probootstrap-media .probootstrap-media-image {
          width: 100%;
          height: 200px; } }
    .probootstrap-media .media-body {
      padding: 2em; }
  
  .probootstrap-form {
    margin-bottom: 0px;
    padding: 40px;
    background: #fff;
    margin-bottom: 50px;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0px; }
    @media (max-width: 991px) {
      .probootstrap-form {
        padding: 15px; } }
    .probootstrap-form image {
      font-weight: normal; }
    .probootstrap-form .form-control {
      height: 46px;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 4px;
      border: 1px solid #d9d9d9; }
      .probootstrap-form .form-control:active, .probootstrap-form .form-control:focus, .probootstrap-form .form-control:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #00CA4C; }
    .probootstrap-form .form-group:last-child {
      margin-bottom: 0;
      padding-bottom: 0; }
    .probootstrap-form .form-group label {
      text-transform: uppercase;
      font-size: 13px; }
    .probootstrap-form .form-group .form-field {
      position: relative; }
      .probootstrap-form .form-group .form-field .icon {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 20px; }
      .probootstrap-form .form-group .form-field .form-control {
        padding-right: 50px; }
    .probootstrap-form textarea.form-control {
      height: inherit;
      resize: vertical; }
  
  .probootstrap-contact-details {
    padding: 0;
    margin: 0; }
    .probootstrap-contact-details li {
      padding: 0;
      margin: 0 0 30px 0;
      line-height: 1.4;
      list-style: none; }
      .probootstrap-contact-details li > span {
        font-weight: 400 !important;
        display: block;
        font-size: 16px;
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.3); }
  
  .select2-container--default .select2-selection--single,
  .select2-dropdown {
    border: 1px solid #d9d9d9; }
    .select2-container--default .select2-selection--single:active, .select2-container--default .select2-selection--single:focus,
    .select2-dropdown:active,
    .select2-dropdown:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 1px solid #00CA4C; }
  
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #00CA4C; }
  
  .select2-container--default .select2-results__option[aria-selected=true] {
    background: #d9d9d9;
    color: #000; }
  
  .select2-selection {
    -webkit-box-shadow: 0;
    box-shadow: 0;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    color: #555555;
    font-size: 16px;
    text-transform: none;
    outline: 0;
    min-height: 45px;
    text-align: left; }
    .select2-selection .select2-selection__rendered {
      margin: 7px; }
    .select2-selection .select2-selection__arrow {
      margin: 7px; }
  
  .select2-search__field {
    outline: none !important;
    padding-left: 10px !important;
    padding-right: 10px !important; }
    .select2-search__field:focus, .select2-search__field:active {
      outline: none !important; }
  
  .owl-carousel .owl-stage-outer {
    padding-bottom: 20px;
    padding-top: 20px; }
  
  .owl-carousel .owl-item {
    opacity: .4; }
    .owl-carousel .owl-item.active {
      opacity: 1; }
  
  .owl-carousel .probootstrap-slide {
    display: block;
    position: relative;
    padding: 7rem 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    text-align: center; }
    .owl-carousel .probootstrap-slide em {
      display: block;
      position: absolute;
      bottom: 10px;
      color: #999999;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      font-style: normal;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: .4s all ease;
      -o-transition: .4s all ease;
      transition: .4s all ease;
      font-size: 13px;
      line-height: 1.4; }
    .owl-carousel .probootstrap-slide span {
      color: #000;
      position: absolute;
      top: 50%;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .owl-carousel .probootstrap-slide span:before {
        font-size: 100px; }
    .owl-carousel .probootstrap-slide:hover, .owl-carousel .probootstrap-slide:focus {
      bottom: 10px;
      -webkit-box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.05);
      box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.05); }
      .owl-carousel .probootstrap-slide:hover em, .owl-carousel .probootstrap-slide:focus em {
        bottom: 30px;
        opacity: 1;
        visibility: visible; }
      .owl-carousel .probootstrap-slide:hover span, .owl-carousel .probootstrap-slide:focus span {
        top: 40%;
        color: #00CA4C; }
  
  .owl-carousel .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%; }
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next {
      position: absolute;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-top: -10px; }
      .owl-carousel .owl-nav .owl-prev span:before,
      .owl-carousel .owl-nav .owl-next span:before {
        font-size: 40px; }
    .owl-carousel .owl-nav .owl-prev {
      left: 0 !important;
      border: 1px solid red; }
    .owl-carousel .owl-nav .owl-next {
      right: 0 !important; }
  
  .owl-carousel .owl-dots {
    text-align: center; }
    .owl-carousel .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      margin: 5px;
      border-radius: 50%;
      background: #e6e6e6; }
      .owl-carousel .owl-dots .owl-dot.active {
        background: #00CA4C; }
  
  .probootstrap-date-wrap {
    position: relative; }
    .probootstrap-date-wrap .icon {
      position: absolute;
      top: 50%;
      color: #ccc;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 10px; }
  
  .font-light {
    font-weight: 300; }
  
  .probootstrap-animate {
    opacity: 0;
    visibility: hidden; }
  #section-services{
        border-top: 1px solid #e6e6e6;
  }
  .accommodation{
    background-color: #00CA4C;
    height: 100px;
  }
  .swiper-button-next{
    border: 1px solid #000000;
    border-radius: 50%;
    padding: 32px;
    background-color: #0000009c;
    color: white;

  }
  .swiper-button-prev{
    border: 1px solid #000000;
    border-radius: 50%;
    padding: 32px;
    background-color: #0000009c;
    color: white;
  }