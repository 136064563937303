main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-family: "Roboto", sans-serif;
  }
  
  .card {
    width: 20rem;
    height: 30rem;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: #f0f0f0;
    box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  }
  .card img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
    transition: opacity 0.2s ease-out;
  }
  .card h2 {
    position: absolute;
    inset: auto auto 30px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    text-transform: uppercase;
  }
  .card p,
  .card a {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity 0.3s ease-out;
  }
  .card p {
    inset: auto auto 80px 30px;
  }
  .card a {
    inset: auto auto 40px 30px;
    color: inherit;
    text-decoration: none;
  }
  .card:hover h2 {
    color: rgba(0, 0, 0, 0.644) !important;
    inset: auto auto 270px 30px;
    transition: inset 0.3s ease-out;
  }
  .card:hover p {
    color: rgba(0, 0, 0, 0.644) !important;
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in;
  }
  .card:hover a {
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in;
  }
  .card:hover img {
    transition: opacity 0.3s ease-in;
    opacity: .5;
  }
  
  .material-symbols-outlined {
    vertical-align: middle;
  }