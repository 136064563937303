
.mtLeft{
    height: 200px;
    width: 200px;
    background-color: aqua;
    border-radius: 50%;
   

}
.mtCenter{
    height: 200px;
    width: 350px;
    background-color: aqua;
}
.mtRight{
    height: 200px;
    width: 200px;
    background-color: aqua;
    border-radius: 50%;
   
}



/* body {
    margin: 23px;
    font-family: "Trebuchet MS", sans-serif;
    font-size: 15.5px;
  } */
/*   
  * {
    box-sizing: border-box;
  } */
  
  .containermustTry {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
    grid-auto-rows: 200px;
    grid-auto-flow: row dense;
    grid-gap: 23px;
    justify-content: center;
    cursor: pointer;
  }
  
  .card-top, .card-right, .card-bottom, .card-left {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    border-radius: 95.2380952381px;
    box-shadow: 0px 3px 9px 1px rgba(0, 10, 20, 0.2);
  }
  
  
  .card-left {
    flex-direction: row;
    grid-column: auto/span 2;
    grid-row: auto/span 1;
  }
  
  .card-image {
    display: flex;
  }
  
  .card-top .card-image, .card-bottom .card-image {
    height: 50%;
    width: 100%;
  }
  
  .card-left .card-image, .card-right .card-image {
    height: 100%;
    width: 50%;
  }
  
  .card-image img {
    width: 100%;
    object-fit: cover;
  }
  
  .card-top img {
    border-radius: 95.2380952381px 95.2380952381px 0 0;
  }
  
  .card-right img {
    border-radius: 0 95.2380952381px 95.2380952381px 0;
  }
  
  .card-bottom img {
    border-radius: 0 0 95.2380952381px 95.2380952381px;
  }
  
  .card-left img {
    border-radius: 95.2380952381px 0 0 95.2380952381px;
  }
  
  .card-text {
    align-self: center;
    padding: 23px;
    font-size: 2vh;
    
  }
  
  .card-top .card-text {
    height: auto;
    width: auto;
    padding-bottom: 36.8px;
  }
  
  .card-right .card-text {
    height: auto;
    width: 50%;
    padding-left: 36.8px;
  }
  
  .card-bottom .card-text {
    height: auto;
    width: auto;
    padding-top: 36.8px;
  }
  
  .card-left .card-text {
    height: auto;
    width: 50%;
    padding-right: 36.8px;
  }
  
  .card-text p {
    margin: 0;
    line-height: 1.35em;
    color: #334455;
  }
  
  @media (max-width: 500px) {
    .card-top {
      flex-direction: row;
      grid-column: auto/span 2;
      grid-row: auto/span 1;
    }
  
    .card-bottom {
      flex-direction: row-reverse;
      grid-column: auto/span 2;
      grid-row: auto/span 1;
    }
  
    .card-top .card-image, .card-bottom .card-image {
      height: 100%;
      width: 50%;
    }
  
    .card-top img {
      border-radius: 95.2380952381px 0 0 95.2380952381px;
    }
  
    .card-bottom img {
      border-radius: 0 95.2380952381px 95.2380952381px 0;
    }
  
    .card-top .card-text {
      height: auto;
      width: 50%;
      padding-right: 36.8px;
    }
  
    .card-bottom .card-text {
      height: auto;
      width: 50%;
      padding-left: 36.8px;
    }
  }
  @media (max-width: 400px) {
    .card-image {
      width: 38% !important;
    }
  
    .card-text {
      width: 62% !important;
    }
  }